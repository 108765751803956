.Join{
  display: flex;
  padding: 0 2rem;
  gap: 10rem;
}

.left-j{
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}

.left-j>hr{
  position: absolute;
  width: 10.5rem;
  border: 2px solid var(--orange);
  border-radius: 20%;
  margin: -10px 0;
}

.left-j>div{
  display: flex;
  gap: 1rem;
}

.right-j{
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.email-container{
  display: flex;
  gap: 3rem;
  background-color: gray;
  padding: 1rem 2rem;
}

.email-container>input{
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--lightgrayz);
}

::placeholder{
  color: var(--lightgray);
}

btn-j{
  background-color: var(--orange);
  color: white;
}

@media screen and (max-width:768px) {
.Join{
  flex-direction: column;
  gap: 1rem;
}

.left-j{
  font-size: x-large;
  flex-direction: column;
}

.right-j{
  padding: 2rem;
}

}


/* updated code */
/* Join.css */
.join-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background-color: #333;
  color: #fff;
  border-radius: 8px;
  width: 300px;
  margin: 0 auto;
}

.join-form input,
.join-form select,
.join-form button {
  padding: 10px;
  border: none;
  border-radius: 4px;
}

.join-form input[type="text"],
.join-form input[type="email"],
.join-form input[type="tel"],
.join-form input[type="number"] {
  width: 100%;
}

.join-form button {
  background-color: #ff5722;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.join-form button:hover {
  background-color: #e64a19;
}
